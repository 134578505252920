import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvailableDepartment, URLUtils } from '@experian-digital/affordability-library';
import { Observable } from 'rxjs';
import { Department } from '../models/department';
import { AuditEventsEnum } from './../models/enums/audit-events.enum';
import { ApplicationConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {
  constructor(private httpClient: HttpClient) {}

  getDepartment(departmentName: string): Observable<Department> {
    return this.httpClient.get<Department>(
      ApplicationConfigService.config.apiUrl + '/api/departments/' + departmentName,
    );
  }

  postDepartmentEvent(event: AuditEventsEnum): Observable<Record<string, never>> {
    const url: string = URLUtils.sanitizeUrl(ApplicationConfigService.config.apiUrl + '/api/departments/events');
    return this.httpClient.post<Record<string, never>>(url, { name: event });
  }

  getUserDepartments(): Observable<AvailableDepartment[]> {
    const url: string = URLUtils.sanitizeUrl(ApplicationConfigService.config.apiUrl + '/api/advisors/departments');
    return this.httpClient.get<AvailableDepartment[]>(url);
  }

  postOnboardDepartments(): Observable<AvailableDepartment[]> {
    const url: string = URLUtils.sanitizeUrl(ApplicationConfigService.config.apiUrl + '/api/advisors/onboard');
    return this.httpClient.post<AvailableDepartment[]>(url, {});
  }
}
